'use strict';

class Gallery {
  constructor() {
    (function($) {
      $(document).ready(() => {
      });
    })(jQuery);
  }
}


var gallery: Gallery = new Gallery();
